import * as React from 'react'

export default (props: any) => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <path
      d="M17.5 6h-15a.5.5 0 0 1 0-1h15a.5.5 0 0 1 0 1zM17.5 11h-15a.5.5 0 0 1 0-1h15a.5.5 0 0 1 0 1zM17.5 16h-15a.5.5 0 0 1 0-1h15a.5.5 0 0 1 0 1z"
      fill="currentColor"
    />
  </svg>
)
