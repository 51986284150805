import { graphql, StaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import Header from '../components/Header'
import Layout from '../components/Layout'
import Sidebar from '../components/Sidebar'
import { HomePageQuery } from '../graphql-types'

const IndexPage: React.FC = () => {
  return (
    <StaticQuery
      query={graphql`
        query HomePage {
          allRestaurantsJson(sort: { order: ASC, fields: [sort] }) {
            edges {
              node {
                name
                slug
                address1
                address2
                phone
              }
            }
          }
        }
      `}
      render={(data: HomePageQuery) => (
        <>
          <div className="grid grid-cols-5">
            <div className="hidden lg:block lg:col-span-1 border-gray-300 border-r">
              <Sidebar />
            </div>
            <div className="col-span-5 lg:col-span-4">
              <Header />
              <div className="p-4">
                <StaticImage
                  src="../images/banner.jpg"
                  alt="Pizza"
                  className="w-full"
                />
              </div>
              <main className="p-6 max-w-7xl m-auto">
                <h2 className="mb-4 text-2xl bolder uppercase">
                  Select a location
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2">
                  {data.allRestaurantsJson.edges.map(({ node }) => (
                    <div key={node.slug}>
                      <a
                        href={node.slug}
                        className="underline text-lg my-2 block"
                      >
                        {node.name}
                      </a>
                      <div className="mb-6 text-sm">
                        {node.address1}
                        <br />
                        {node.address2}
                        <br />
                        {node.phone}
                      </div>
                    </div>
                  ))}
                </div>
              </main>
            </div>
          </div>
        </>
      )}
    />
  )
}

export default IndexPage
