import React from 'react'
import Nav from './Nav'

const Header = () => (
  <>
    <Nav />
    <header className="flex justify-center py-3 border-b border-solid mb-4">
      <a href="/">
        <h1 className="text-red-600 text-3xl font-normal">Giant Pizza King</h1>
      </a>
    </header>
    <div className="text-2xl text-center">
      If you don't see the KING, It's not the REAL THING!
    </div>
  </>
)

export default Header
