import * as React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import { SidebarRestaurantsQuery } from '../graphql-types'
import { StaticImage } from 'gatsby-plugin-image'

const Sidebar: React.FC = () => {
  return (
    <div className="p-6 sticky top-0 max-h-screen overflow-scroll bg-white">
      <a href="/">
        <StaticImage
          src="../images/logo.png"
          alt="king holding two slices of pizza"
        />
      </a>
      <div className="text-gray-500 text-2xl py-5 border-b border-gray-500 mb-4">
        Our Locations
      </div>
      <StaticQuery
        query={query}
        render={(data: SidebarRestaurantsQuery) => (
          <div>
            {data.allRestaurantsJson.edges.map(({ node: { slug, name } }) => (
              <Link
                key={slug}
                className="text-gray-500 block uppercase font-bold text-lg hover:bg-gray-300 p-3 hover:text-black"
                to={slug}
              >
                {name}
              </Link>
            ))}
          </div>
        )}
      />
    </div>
  )
}

export default Sidebar

export const query = graphql`
  query SidebarRestaurants {
    allRestaurantsJson(sort: { order: ASC, fields: sort }) {
      edges {
        node {
          id
          slug
          name
        }
      }
    }
  }
`
