import { useEffect } from 'react'

export function useOutsideDetector(ref, ignoreRef, effect: (v: any) => void) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ignoreRef.current && ignoreRef.current.contains(event.target)) {
        return
      }
      if (ref.current && !ref.current.contains(event.target)) {
        effect(false)
      } else {
        effect(true)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}
