import { StaticImage } from 'gatsby-plugin-image'
import React, { useRef } from 'react'
import { useOutsideDetector } from '../hooks/useOutsideClickDetector'
import Menu from '../svg/Menu'
import Sidebar from './Sidebar'

const Nav: React.FC = () => {
  const [showSidebar, setShowSidebar] = React.useState(false)

  const ref = useRef(null)
  const ignoreRef = useRef(null)
  useOutsideDetector(ref, ignoreRef, setShowSidebar)

  return (
    <>
      <header className="flex justify-between items-center p-5 bg-black lg:hidden">
        <a href="/">
          <StaticImage
            src="../images/logo.png"
            alt="king holding two slices of pizza"
            className="w-12"
          />
        </a>
        <button
          ref={ignoreRef}
          className="text-white flex items-center focus:outline-none text-lg"
          onClick={() => setShowSidebar(!showSidebar)}
        >
          <Menu />
          Locations
        </button>
      </header>
      <div
        ref={ref}
        className={` z-10 h-screen bg-white w-72 fixed top-0 transition-all lg:-left-96 ${
          showSidebar ? 'left-0' : '-left-96'
        }`}
      >
        <div className="relative">
          <button
            className="absolute top-2 right-2 z-20"
            onClick={() => setShowSidebar(false)}
          >
            x
          </button>
          <Sidebar />
        </div>
      </div>
    </>
  )
}

export default Nav
